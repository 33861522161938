import { reducerBuilder } from '../../functions/redux';
import * as type from './types';

const initialState = {
  time: 0,
  isRunning: false,
  startTime: null,
};

export const timerReducer = reducerBuilder(initialState, (builder) =>
  builder
    .addCase(type.TIMER_START, (state, { payload }) => {
      state.time = 0;
      state.startTime = payload?.startTime;
      state.isRunning = true;
    })
    .addCase(type.TIMER_SET, (state, { payload }) => {
      state.startTime = payload?.startTime;
      state.time = (payload?.endTime - payload?.startTime) / 1000;
      state.isRunning = payload?.isRunning;
    })
    .addCase(type.TIMER_STOP, (state) => {
      state.isRunning = false;
    })
    .addCase(type.TIMER_RESET, (state) => {
      state.time = 0;
      state.isRunning = false;
    })
    .addCase(type.TIMER_TICK, (state) => {
      state.time = state.isRunning
        ? Math.round((new Date().getTime() - state.startTime) / 1000)
        : 0;
      state.isRunning = true;
    }),
);
