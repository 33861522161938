import { useState, useEffect } from 'react';
import useTimer from '../../../hooks/useTimer';
import { useCreateCallTransaction, useEndCall, useStartCall } from './use-call-query';
import { formatToDate, getCurrentTime } from '../../../utils/date-time';
import { openAlert } from '../../../components/alert/useAlert';

export const CALL_STATUS = {
  IDLE: 'idle',
  CALLING: 'calling',
  WAITING_CONFIRM: 'waiting-confirm',
};

function useCallAction({ contactId, companyId, onCallDetails, onSubmit }) {
  const [callState, setCallState] = useState({
    status: CALL_STATUS.IDLE,
    startDetails: null,
    endDetails: null,
  });

  const timer = useTimer();
  const startCall = useStartCall();
  const endCall = useEndCall();
  const createTransaction = useCreateCallTransaction();

  useEffect(() => {
    if (onCallDetails?.startTime && onCallDetails?.endTime) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.WAITING_CONFIRM,
      }));

      timer.set({
        startDate: onCallDetails?.startTime,
        endDate: onCallDetails?.endTime,
      });
    } else if (onCallDetails?.startTime) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.CALLING,
      }));

      timer.set({ startDate: onCallDetails?.startTime });
    } else {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
      }));

      timer.reset();
    }
  }, []);

  const handleStartCall = async () => {
    try {
      const startDate = getCurrentTime();

      const reqBody = {
        companyId: companyId,
        userContactId: contactId,
        startTime: startDate,
      };

      timer.start({ startDate: startDate });

      const response = await startCall.mutateAsync(reqBody);
      if (response?.success) {
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.CALLING,
          startDetails: response?.result,
        }));
      }
    } catch (err) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
      }));
      timer.reset();
    }
  };

  const handleEndCall = async () => {
    try {
      const callId = onCallDetails?.onCallId || callState.startDetails?._id;

      if (!callId) return;

      const params = {
        callId: callId,
        reqBody: {
          endTime: getCurrentTime(),
        },
      };
      timer.stop();

      const response = await endCall.mutateAsync(params);

      if (response?.success) {
        setCallState((prev) => ({
          ...prev,
          status: CALL_STATUS.WAITING_CONFIRM,
          endDetails: response?.result,
        }));
      }
    } catch (error) {
      setCallState((prev) => ({
        ...prev,
        status: CALL_STATUS.IDLE,
      }));
      timer.reset();
    }
  };

  const handleSubmitTransaction = ({ onCallBack }) => {
    const triggerTransaction = async (formValues) => {
      const reqBody = {
        ...formValues,
        callId: onCallDetails?.onCallId || callState.startDetails?._id,
        contactId: onCallDetails?.customerId || callState.startDetails?.customer_id || contactId,
        followUpDate: formatToDate(formValues?.followUpDate),
      };

      const response = await createTransaction.mutateAsync(reqBody);

      if (response?.success) {
        onCallBack();
      }
    };

    openAlert({
      model: 'confirm',
      title: 'คุณต้องการบันทึกการโทรใช่หรือไม่ ?',
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: () => {
        onSubmit({ onAction: triggerTransaction });
      },
      onCancel: () => {},
    });
  };

  const callTimerProps = {
    ...timer,
    status: callState?.status,
    onTriggerStartCall: handleStartCall,
    onTriggerEndCall: handleEndCall,
  };

  return {
    callTimerProps,
    onSubmitTransaction: handleSubmitTransaction,
  };
}

export default useCallAction;
