import { Space } from 'antd';
import React from 'react';
import Text from '../../../../../../components/text-new';
import { ButtonTheme } from '../../../../../../components/buttons';
import { color } from '../../../../../../resources';
import { formatSecondsToTime } from '../../../../../../utils/date-time';
import { CALL_STATUS } from '../../../../hooks/use-call-action';

function CallTimer(props) {
  return (
    <div>
      <Space style={{ width: '100%' }} direction="vertical" align="center">
        <Text variant="span" color={color.grey100} size={20}>
          จับเวลาการโทรติดต่อ
        </Text>
        <Text variant="span" color={!props.isRunning ? color.grey400 : color.redStatus} size={40}>
          {formatSecondsToTime(props.time)}
        </Text>

        {props.status !== CALL_STATUS.CALLING ? (
          <ButtonTheme
            useFor="CUSTOM"
            title="เริ่มการโทร"
            type="primary"
            color={color.green80}
            onClick={props.onTriggerStartCall}
            disabled={props?.status === CALL_STATUS.WAITING_CONFIRM}
          />
        ) : (
          <ButtonTheme
            useFor="CUSTOM"
            title="สิ้นสุดการโทร"
            type="primary"
            color={color.redStatus}
            onClick={props.onTriggerEndCall}
          />
        )}
      </Space>
    </div>
  );
}

export default CallTimer;
