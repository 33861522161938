import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../redux/timer/action';

function useTimer() {
  const dispatch = useDispatch();

  const time = useSelector((state) => state.timer.time);
  const isRunning = useSelector((state) => state.timer.isRunning);

  const start = (items) => {
    const startTime = items?.startDate
      ? new Date(items?.startDate).getTime()
      : new Date().getTime();

    dispatch(actions.startTimer({ startTime }));
  };

  const set = (items) => {
    const startTime = items?.startDate
      ? new Date(items?.startDate).getTime()
      : new Date().getTime();

    const endTime = items?.endDate ? new Date(items?.endDate).getTime() : new Date().getTime();

    dispatch(actions.setTimer({ startTime, endTime, isRunning: !items?.endDate }));
  };

  const stop = () => dispatch(actions.stopTimer());

  const reset = () => {
    dispatch(actions.resetTimer());
  };

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        dispatch(actions.tickTimer());
      }, 1000);
    } else if (!isRunning) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, dispatch, time]);

  return {
    time,
    isRunning,
    set,
    start,
    stop,
    reset,
  };
}

export default useTimer;
